import moment from 'moment-timezone'

const abbreviatedMonthNames = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sept.',
  'Oct.',
  'Nov.',
  'Dec.',
]

const getAbbreviatedMonthName = (monthNumber: number) => {
  return abbreviatedMonthNames[monthNumber]
}

const getAbbreviatedDate = (time: string | Date | undefined | null) => {
  if (!time) return null
  const date = time instanceof Date ? time : new Date(time)
  const month = getAbbreviatedMonthName(date.getUTCMonth())

  return `${month} ${date.getUTCDate()}, ${date.getFullYear()}`
}

const getPrettyDate = (time: string | Date | undefined | null) => {
  if (!time) return null
  const date = time instanceof Date ? time : new Date(time)
  const month = date.toLocaleString('default', { month: 'long' })
  return `${month} ${date.getUTCDate()}, ${date.getFullYear()}`
}

const formatDateFilterString = (date: Date) => {
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  const year = date.getFullYear()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, month, day].join('-')
}

const formatTimeSpan = (startTime: Date, endTime: Date) => {
  // Ensure inputs are Date objects
  const start = new Date(startTime)
  const end = new Date(endTime)

  // Options for formatting hours and minutes
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }

  // Use Intl.DateTimeFormat to format the times
  const startTimeFormatted = new Intl.DateTimeFormat(
    'en-US',
    timeOptions,
  ).format(start)
  const endTimeFormatted = new Intl.DateTimeFormat('en-US', timeOptions).format(
    end,
  )
  const meetingTimeFormatted = `${startTimeFormatted} – ${endTimeFormatted} EST`
  return meetingTimeFormatted
}

const convertToLocalTime = (startTime: Date, endTime: Date) => {
  const start = moment.utc(startTime).tz(moment.tz.guess()).format('h:mm A')
  const end = moment.utc(endTime).tz(moment.tz.guess()).format('h:mm A')
  const meetingTimeFormatted = `${start} – ${end}`
  return meetingTimeFormatted
}

const formatDateNoYear = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'long' })
  // get day of the week from date
  const day = date.toLocaleString('default', { weekday: 'long' })
  return `${day}, ${month} ${date.getUTCDate()}`
}

const formatDateNoYearNaive = (date: Date) => {
  const month = date.toLocaleString('default', { month: 'long' })
  // get day of the week from date
  const day = date.toLocaleString('default', { weekday: 'long' })
  return `${day}, ${month} ${date.getDate()}`
}

export {
  getAbbreviatedDate,
  getPrettyDate,
  formatDateFilterString,
  formatTimeSpan,
  formatDateNoYear,
  formatDateNoYearNaive,
  convertToLocalTime,
}
