'use client'

import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react'
import { createPortal } from 'react-dom'
import Icon, { Icons } from '@crystal-eyes/components/elements/Icon/Icon'
import styles from './Drawer.module.scss'
const ANIMATION_DURATION = 300
const DEFAULT_MARGIN_TOP = 24
const HEADER_PADDING = 20
const CLOSING_PERCENTAGE = 65

export default function Drawer({
  children,
  wrapperId = 'crystal-eyes-drawer',
  parentClassName = '',
  className = '',
  close,
  isOpen = false,
}: {
  children: React.ReactNode
  wrapperId?: string
  parentClassName?: string
  className?: string
  marginTop?: number
  close: () => void
  isOpen: boolean
}) {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null)
  const [openState, setOpenState] = useState<
    'open' | 'opening' | 'closing' | 'closed'
  >('closed')

  useEffect(() => {
    if (isOpen) {
      setOpenState('opening')
      setTimeout(() => {
        setOpenState('open')
      }, ANIMATION_DURATION)
    } else {
      setOpenState('closing')
      setTimeout(() => {
        setOpenState('closed')
      }, ANIMATION_DURATION)
    }
  }, [isOpen])

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId)
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId)
    }
    setWrapperElement(element)
  }, [wrapperId])

  if (wrapperElement === null || openState === 'closed') return null
  return createPortal(
    <DrawerWrapper
      parentClassName={parentClassName}
      className={className}
      openState={openState}
      close={close}
      meetingDrawerOpen={isOpen}
    >
      {children}
    </DrawerWrapper>,
    wrapperElement,
  )
}

function DrawerWrapper({
  children,
  className,
  openState,
  meetingDrawerOpen,
  parentClassName,
  close,
}: {
  children: React.ReactNode
  className: string
  meetingDrawerOpen: boolean
  openState: 'open' | 'opening' | 'closing' | 'closed'
  parentClassName: string
  close: () => void
}) {
  const [mousePressed, setMousePressed] = useState<boolean>(false)
  const [showScrollToTop, setShowScrollToTop] = useState<boolean>(false)
  const [dragOffSet, setDragOffSet] = useState<number>(0)
  const [marginTop, setMarginTop] = useState<number>(24)

  const handleClickOutside = () => {
    if (openState === 'open') {
      close()
    }
  }

  const dragNDrop = useCallback(
    (e: MouseEvent) => {
      if (!meetingDrawerOpen || !topRef.current || !mousePressed) return
      const mousePos = e.clientY
      setMarginTop(mousePos - dragOffSet)
      const windowHeight = window.innerHeight
      const diffPercentage = ((windowHeight - mousePos) / windowHeight) * 100
      if (diffPercentage < CLOSING_PERCENTAGE) {
        close()
        setMarginTop(DEFAULT_MARGIN_TOP)
        setMousePressed(false)
      }
    },
    [meetingDrawerOpen, dragOffSet, mousePressed, close],
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!mousePressed || !meetingDrawerOpen || !topRef.current) return
      dragNDrop(e)
    },
    [mousePressed, meetingDrawerOpen, dragNDrop],
  )

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!meetingDrawerOpen || !topRef.current) return
    e.preventDefault()
    const mousePos = e.clientY
    const topPos = topRef.current.getBoundingClientRect().top
    setDragOffSet(mousePos - topPos + HEADER_PADDING)
    setMousePressed(true)
  }

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      if (!meetingDrawerOpen || !topRef.current) return
      const mousePos = e.clientY
      const windowHeight = window.innerHeight
      const diffPercentage = ((windowHeight - mousePos) / windowHeight) * 100
      setMousePressed(false)
      if (diffPercentage >= CLOSING_PERCENTAGE) {
        setMarginTop(DEFAULT_MARGIN_TOP)
      }
    },
    [meetingDrawerOpen],
  )

  const topRef = useRef<HTMLDivElement>(null)
  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    const ref = topRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShowScrollToTop(!entry.isIntersecting)
      },
      { threshold: 0.5 },
    )
    if (ref) {
      observer.observe(ref)
    }
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [mousePressed, handleMouseMove, handleMouseUp])

  return (
    <div
      className={`${styles.drawer} ${parentClassName}`}
      onClick={handleClickOutside}
    >
      <div
        style={{ marginTop: `${marginTop}px` }}
        className={`drawer-content ${className} ${openState}`}
        onClick={evt => {
          evt.stopPropagation()
        }}
      >
        <div ref={topRef} className={styles.drawerHeader}>
          <div
            className={styles.drawerHeaderBar}
            onMouseDown={e => handleDragStart(e)}
          ></div>
          <div className={styles.drawerHeaderRight}>
            <span className={styles.iconWrapper} onClick={close}>
              <Icon icon={Icons.Close} className={styles.icon} />
            </span>
          </div>
        </div>
        {children}
        {meetingDrawerOpen && showScrollToTop && (
          <div
            className={`${styles.scrollToTop} CE tooltip`}
            onClick={scrollToTop}
          >
            <Icon icon={Icons.Chevron} className={styles.scrollIcon} />
            <div className={`CE tooltip-content ${styles.scrollPopUp}`}>
              Scroll to Top
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}
