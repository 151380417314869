import { MeV2 } from '@crystal-eyes/types'
import useAuth from '@crystal-eyes/hooks/useAuth'
import { queryWithAuth, gql } from '@crystal-eyes/utils/apis/graphqlApi'
import useSWR from 'swr'

export enum ProductAccess {
  Hiring = 'HIRING',
  Sales = 'SALES',
  Leadership = 'LEADERSHIP',
}

export enum PersonalizationTypes {
  JobCandidate = 'JOB_CANDIDATE',
  Customer = 'CUSTOMER',
  Coworker = 'COWORKER',
}

export const ME_QUERY = gql`
  query GetMeV2 {
    org {
      id
    }

    me {
      id
      isOrgAdmin
      products
      personalizations
      features
      createdAt
      sidenavFeatureFlag

      emails {
        value
        type
      }

      usageLimits {
        allowance
        usage
        actionNeeded
      }

      identity {
        id

        personalInfo {
          companyName
          firstName
          lastName
          fullName
          photoUrl
        }

        rootSnapshot {
          id
          vanityUrl
        }

        snapshot {
          id

          personality {
            discType
            discCircumplex {
              degrees
              intensity
            }
          }
        }
      }
    }
  }
`

export type State = {
  data: MeV2
  loading?: boolean
  error?: any
}

const UNAUTHED_ME: MeV2 = {
  id: 'unauthed-user-me-id',
  isOrgAdmin: false,
  products: [],
  personalizations: [],
  features: [],
}

export default function useMeV2(opts: { pagesRouter?: boolean } = {}): State {
  const { data: auth } = useAuth()

  const fetcher = (__key: string) =>
    queryWithAuth(auth, ME_QUERY, opts).then(({ me, org }) => ({ ...me, org }))

  const {
    data: queryData,
    isLoading: loading,
    error,
  } = useSWR(auth?.authed ? 'global.user.me.v2' : null, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    refreshInterval: 1000 * 60 * 60,
  })

  if (!auth)
    return {
      data: UNAUTHED_ME,
    }

  return {
    data: queryData,
    loading,
    error,
  }
}
